import {Form, Row, Col, Button, Table, Pagination, ListGroup, Spinner } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import {BACKEND} from './../config';
import { SortDown, SortUp, XCircleFill, FiletypeCsv, FiletypeJson, FiletypeXml, FiletypeXls } from 'react-bootstrap-icons';
import InfoModal from '../components/InfoModal';
import YesNoModal from '../components/YesNoModal';
import exportFromJSON from "export-from-json";

import 'bootstrap/dist/css/bootstrap.min.css';
import './../App.css';

function BloodPressureList() {
    const [bloodPressureList, setBloodPressureList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState([15]);
    const [filterConditions, setFilterConditions] = useState([]);
    const [orderColumn, setOrderColumn] = useState("id");
    const [order, setOrder] = useState("DESC");
    const [columnNames, setColumnNames] = useState([{name: "id", text: "ID", type: 0}, {name: "creationDate", text: "Časové razítko", type: 1}, {name: "personalId", text: "Rodné číslo", type: 2},
                                        {name: "gender", text: "Pohlaví", type: 2}, {name: "birthday", text: "Datum narození", type: 1},
                                        {name: "heartRate", text: "Tep", type: 0}, {name: "method", text: "Metoda", type: 2}, {name: "sysPressureO", text: "SYS O", type: 0},
                                        {name: "meanPressureO", text: "MAP", type: 0}, {name: "diasPressureO", text: "DIA O", type: 0},
                                        {name: "sysPressureA", text: "SYS A", type: 0}, {name: "diasPressureA", text: "DIA A", type: 0,},
                                        {name: "rhytmDisorders", text: "Srdeční rytmus", type: 2},
                                        {name: "hypertensionClass", text: "Klasifikace hypertenze", type: 2}, {name: "weight", text: "Hmotnost", type: 0},
                                        {name: "height", text: "Výška", type: 0}, {name: "bmi", text: "BMI", type: 0}]);
    const [totalPages, setTotalPages] = useState(0);
    const [pagesToRender, setPagesToRender] = useState([]);
    const [filterColumn, setFilterColumn] = useState("");
    const [filterOperator, setFilterOperator] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [filterType, setFilterType] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
    const [text, setText] = useState("");
    const [itemToRemove, setItemToRemove] = useState({});
    const [csvData, setCsvData] = useState(undefined)
    const [loadingExport, setLoadingExport] = useState(false);
    
    useEffect(() => {
        fetchFilteredData();
    }, [currentPage, orderColumn, order, filterConditions])

    function fetchExport(){
        setLoadingExport(true);

        fetch(`${BACKEND.ADDRESS}/bloodPressure/export`, 
        {credentials: 'include'})
        .then(response => response.json())
        .then(json => {
            if(json.message === "no blood pressure"){
                setText("V databázi se nenachází žádný záznam.");
                setShowModal(true);
            }
            else if(json.type === 'error'){
                setText("Při načítání dat z databáze došlo k chybě!");
                setShowModal(true);
            }
            else{
                let unformattedData = json;

                for(let i = 0; i < unformattedData.data.length; i++){
                    if(unformattedData.data[i].graphData !== null && unformattedData.data[i].graphData.trim() !== ""){
                        unformattedData.data[i].graphData = JSON.parse(unformattedData.data[i].graphData);
                    }
                    else{
                        unformattedData.data[i].graphData = [];
                    }
                }
                setCsvData(unformattedData);
            }
        })
        .catch(error => {
            setText("Při načítání dat z databáze došlo k chybě! " + error)
            setShowModal(true);
        })
        .finally(() => {
            setLoadingExport(false);
        });
    }

    function fetchFilteredData(){
        fetch(`${BACKEND.ADDRESS}/bloodPressure/getAllFiltered?currentPage=${currentPage-1}&limit=${limit}&conditions=${JSON.stringify(filterConditions)}&orderColumn=${orderColumn}&order=${order}`, 
        {credentials: 'include'})
        .then(response => response.json())
        .then(json => {
            if(json.message === "no blood pressure"){
                setText("V databázi se nenachází žádný záznam odpovídající zadaným filtrům.");
                setShowModal(true);
            }
            else if(json.type === 'error'){
                setText("Při načítání dat z databáze došlo k chybě! Zkontrolujte, prosím, Vámi zvolené filtry.");
                setShowModal(true);
            }

            setBloodPressureList(json.bloodPressureList)

            let pages = Array.from({length: json.totalPages}, (_, i) => i + 1)

            let pagesVisible = [];
            
            for(let page = parseInt(currentPage)-2; page <= parseInt(currentPage)+2; page++){
                if(pages.includes(page)){
                    pagesVisible.push(page);
                }
            }

            setPagesToRender(pagesVisible)
            setTotalPages(pages)
        })
        .catch(error => {
            setText("Při načítání dat z databáze došlo k chybě! " + error)
            setShowModal(true);
        });
    }


    function changeOrderColumn(column) {
        if(column.name == orderColumn){
            setOrder(order === "ASC" ? "DESC" : "ASC");
        }
        else{
            setOrderColumn(column.name);
        }
    }

    function changeColumn(column) {
        setFilterColumn(column);
        let columnObject = columnNames.filter((col) => {
            return col.name === column;
        });
        setFilterType(columnObject[0].type);
    }

    function addFilterCondition() {
        let conditionList = [...filterConditions];

        if(filterColumn.length < 1 && filterConditions.length < 1 && filterOperator.length < 1){
            return;
        }

        let value = 0;
        if(filterType == 0){
            value = filterValue;
        }
        else if(filterType == 1){
            let splitted = filterValue.split(".");
            value = splitted[2] + "-" + splitted[1]+ "-" + splitted[0];
        }
        else{
            value = filterValue.toLowerCase();
        }

        if(!conditionList.some(condition => condition.column == filterColumn && condition.operator == filterOperator && condition.value == value)){
            conditionList.push({
                column: filterColumn,
                operator: filterType == 2 ? "" : filterOperator,
                value: value,
                type: filterType
            });
        }
        
        setCurrentPage(1);
        setFilterConditions(conditionList);
    }

    function removeFilterCondition(index){
        let conditionList = [...filterConditions];
        conditionList.splice(index, 1);

        setFilterConditions(conditionList);
    }

    function removeBloodPressure(bloodPressureId, index){
        fetch(`${BACKEND.ADDRESS}/bloodPressure/remove`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                bloodPressureId: bloodPressureId
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                setText("Při mazání záznamu z databáze došlo k chybě!")
            }
            else{
                setText("Smazání záznamu proběhlo v pořádku.")
                let blPressureList = [...bloodPressureList];
                blPressureList.splice(index, 1);
                setBloodPressureList(blPressureList);
                
                if(blPressureList.length < 1){
                    if(currentPage > 1){
                        setCurrentPage(currentPage - 1);
                    }
                    else{
                        setCurrentPage(1);
                    }
                }
                else{
                    fetchFilteredData();
                }
            }
        })
        .catch(error => {
            setText("Při mazání záznamu z databáze došlo k chybě! " + error);
        }).finally(() => {
            setShowModal(true);
        });
    }

    function removeAllFilters(){
        if(filterConditions.length != 0){
            setCurrentPage(1);
            setFilterConditions([]);
        }
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const closeDeleteItemModal = () => {
        setShowDeleteItemModal(false);
        setItemToRemove({});
    }

    const continueDeleteItemModal = () => {
        setShowDeleteItemModal(false);
        removeBloodPressure(itemToRemove.id, itemToRemove.index);
        setItemToRemove({});
    }

    function removeBloodPressureItem(id, index){
        setItemToRemove({id: id, index: index});
        setShowDeleteItemModal(true);
    }

    function getDate(date){
        return date.toLocaleDateString();
    }

    function getDateTime(dateTime){
        return dateTime.toLocaleDateString() + " " + dateTime.toLocaleTimeString()
    }

    const exportData = (exportType) => {
        const data = csvData.data;
        const fileName = "bloodPressure" + new Date().toLocaleDateString();
        let fields = [];  //fieldsAsObjects or fieldsAsStrings, empty list means "use all"
        exportFromJSON({data, fileName, fields, exportType})
    }

    return (
        <div className="app">
            <div className="page">
                <div className="content">
                    <div>
                        <Form>
                            <Row>
                                <h5>Filtr dat</h5>
                            </Row>
                            <Form.Group as={Row}>
                                <Col xs="12" sm="4" md="3" xl="3" xxl="2" className="mb-3">
                                    <Form.Control as="select" value={filterColumn} onChange={(event)=>changeColumn(event.target.value)}>
                                        <option key='-1' hidden value>Sloupec</option>
                                        {
                                            columnNames.map((col) => 
                                            <option value={col.name} key={col.name}>{col.text}</option>
                                            )
                                        }
                                    </Form.Control>
                                </Col>
                                <Col xs="8" sm="3" md="2" xl="2" xxl="2" className="mb-3">
                                    <Form.Control as="select" value={filterOperator} onChange={(event)=>setFilterOperator(event.target.value)} disabled={filterType == 2}>
                                        <option key='-1' hidden value>Operátor</option>
                                        <option value={'='}>{'='}</option>
                                        <option value={'<'}>{'<'}</option>
                                        <option value={'<='}>{'<='}</option>
                                        <option value={'>'}>{'>'}</option>
                                        <option value={'>='}>{'>='}</option>
                                        <option value={'!='}>{'!='}</option>
                                    </Form.Control>
                                </Col>
                                <Col xs="12" sm="4" md="3" xl="3" xxl="2" className="mb-3">
                                    <Form.Control 
                                        value={filterValue}
                                        onChange={(event)=>setFilterValue(event.target.value.replaceAll(" ", ""))} 
                                        placeholder="Hodnota"
                                    />    
                                </Col>
                                
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <div className='list-form-buttons'>
                                    <Button onClick={addFilterCondition} style={{width: "144px", marginRight: "10px"}}>Přidat nový filtr</Button> 
                                    <Button onClick={removeAllFilters} variant="outline-danger" style={{width: "144px"}}>Smazat filtry</Button>    
                                </div> 
                            </Form.Group>
                        </Form>
                    </div>
                    {
                        filterConditions.length > 0 &&
                        <Row>
                            <Col>
                                <ListGroup style={{display: "inline-block"}} className="mb-3">
                                    {
                                        filterConditions.map((condition, index) => 
                                            <ListGroup.Item variant="primary" key={index}>
                                                {condition.column + ' ' + condition.operator + ' ' + condition.value} 
                                                <XCircleFill onClick={() => removeFilterCondition(index)} style={{marginLeft: "20px", cursor: "pointer", float: "right", marginTop: "4px"}}></XCircleFill>
                                            </ListGroup.Item>
                                        )
                                    }
                                </ListGroup>
                            </Col>
                        </Row>
                    }
                    <Form>
                        <Form.Group as={Row} className="mb-3">
                            <div className='list-form-buttons'>
                                {
                                    !loadingExport &&
                                    <Button onClick={fetchExport} style={{width: "144px", marginRight: "10px"}}>Export dat</Button> 
                                }
                                {
                                    loadingExport &&
                                    <Button disabled style={{width: "144px", marginRight: "10px"}}>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Načítání...
                                    </Button> 
                                }
                            </div> 
                        </Form.Group>
                        {
                            csvData &&
                            <Form.Group as={Row} className="mb-3">
                                <div className='list-form-buttons'>
                                    <Button onClick={() => exportData('csv')} style={{width: "144px", marginRight: "10px"}} variant="outline-success"><FiletypeCsv size={20}></FiletypeCsv> CSV formát</Button> 
                                    <Button onClick={() => exportData('xls')} style={{width: "144px", marginRight: "10px"}} variant="outline-success"><FiletypeXls size={20}></FiletypeXls> XLS formát</Button>
                                    <Button onClick={() => exportData('json')} style={{width: "144px", marginRight: "10px"}} variant="outline-secondary"><FiletypeJson size={20}></FiletypeJson> JSON formát</Button> 
                                    <Button onClick={() => exportData('xml')} style={{width: "144px", marginRight: "10px"}} variant="outline-primary"><FiletypeXml size={20}></FiletypeXml> XML formát</Button> 
                                </div>
                            </Form.Group>       
                        }
                    </Form>
                    <Table responsive striped bordered hover style={{textAlign: 'center'}}>
                        <thead style={{backgroundColor: "lightgray"}}>
                            <tr>
                                {
                                    columnNames.map((column) => 
                                        <th onClick={() => changeOrderColumn(column)} style={{cursor: "pointer"}} key={column.name}>
                                            {column.text}
                                            {
                                                column.name === orderColumn && (order === "DESC" ? 
                                                <SortDown style={{float: "right", marginTop: "4px"}}></SortDown> : 
                                                <SortUp style={{float: "right", marginTop: "4px"}}></SortUp>)
                                            }
                                        </th>
                                    )
                                }
                                <th>
                                    Akce
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                bloodPressureList && bloodPressureList.map((bloodPressure, index) => 
                                    <tr key={index}>
                                        <td>
                                            {bloodPressure.id}
                                        </td>
                                        <td>
                                            {getDateTime(new Date(bloodPressure.creationDate))}  
                                        </td>
                                        <td>
                                            {bloodPressure.personalId}
                                        </td>
                                        <td>
                                            {bloodPressure.gender}
                                        </td>
                                        <td>
                                            {getDate(new Date(bloodPressure.birthday))}
                                        </td>
                                        <td>
                                            {bloodPressure.heartRate}
                                        </td>
                                        <td>
                                            {bloodPressure.method}
                                        </td>
                                        <td>
                                            {bloodPressure.sysPressureO}
                                        </td>
                                        <td>
                                            {bloodPressure.meanPressureO} 
                                        </td>
                                        <td>
                                            {bloodPressure.diasPressureO}   
                                        </td>
                                        <td>
                                            {bloodPressure.sysPressureA}
                                        </td>
                                        <td>
                                            {bloodPressure.diasPressureA}
                                        </td>
                                        <td>
                                            {bloodPressure.rhytmDisorders}
                                        </td>
                                        <td>
                                            {bloodPressure.hypertensionClass}
                                        </td>
                                        <td>
                                            {bloodPressure.weight}
                                        </td>
                                        <td>
                                            {bloodPressure.height}
                                        </td>
                                        <td>
                                            {bloodPressure.bmi}
                                        </td>
                                        <td>
                                            <XCircleFill onClick={() => removeBloodPressureItem(bloodPressure.id, index)/*removeBloodPressure(bloodPressure.id, index)*/} color='red'style={{cursor: "pointer"}} title="Smazat"></XCircleFill>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    <Pagination style={{justifyContent: "center"}}>
                        <Pagination.First onClick={(e) => setCurrentPage(1)}/>
                        <Pagination.Prev onClick={(e) => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)}/>
                        {
                            totalPages[0] + 2 < currentPage && <Pagination.Ellipsis/>
                        }
                        {
                            pagesToRender && pagesToRender.map((number) =>
                                <Pagination.Item key={number} active={number == currentPage}value={number} onClick={() => {setCurrentPage(number)}}>
                                    {number}
                                </Pagination.Item>
                            )
                        }
                        {
                            totalPages[totalPages.length-1] - 2 > currentPage && <Pagination.Ellipsis/>
                        }
                        <Pagination.Next onClick={(e) => setCurrentPage(currentPage < totalPages.length ? currentPage + 1 : currentPage)}/>
                        <Pagination.Last onClick={(e) => setCurrentPage(totalPages.length)}/>
                    </Pagination>
                    <InfoModal text={text} show={showModal} handleClose={closeModal}></InfoModal>
                    <YesNoModal 
                        text={"Chcete opravdu smazat tento záznam?"} 
                        show={showDeleteItemModal} 
                        handleClose={closeDeleteItemModal} 
                        handleContinue={continueDeleteItemModal}
                    >    
                    </YesNoModal>
                </div>
            </div>
        </div>
    );
}

export default BloodPressureList;