import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import { Navbar, Nav, Container, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { logout } from '../actions/account';
import {history} from '../store';

function CustomNavbar(props){

    const [expanded, setExpanded] = useState(false);

    const logout = () => {
        props.logout().then(()=>history.push('/login'));
    }

    return(
        <React.Fragment>
            <Navbar expanded={expanded} style={{minHeight: "68px", backgroundColor: '#ffffff', boxShadow: "0px 5px 15px lightgrey"}} sticky="top" collapseOnSelect fixed='top' expand={props.loggedIn ? 'xxl' : 'md'}  variant='light'>
            
                <Container style={{maxWidth: '100%'}}>
                    <Navbar.Brand>
                        <img src='logo_fei.png' className='navbar-logo' onClick={() => window.open("https://www.fei.vsb.cz/bme/cs/" , "_blank")} style={{cursor: "pointer"}}></img>
                    </Navbar.Brand>
                    <Navbar.Toggle className='toggle-button' onClick={()=>setExpanded(expanded ? false : true)}/>
                    <Navbar.Collapse style={{ marginLeft: '20px'}} className="justify-content-end">
                        <Nav>
                            {
                                props.account.loggedIn && 
                                <>
                                    <Nav.Link as={Link} to={'/measurement'} style={{marginRight: '15px'}}>Měření krevního tlaku</Nav.Link>
                                    <Nav.Link as={Link} to={'/list'} style={{marginRight: '15px'}}>Seznam měření</Nav.Link>
                                    <Nav.Link as={Link} to={'/about'} style={{marginRight: '15px'}}>O aplikaci</Nav.Link>
                                    <Nav.Link as={Link} to={'/login'} onClick={logout} style={{marginRight: '15px', textDecoration: 'underline'}}>Odhlásit</Nav.Link>
                                </>
                            }
                            {
                                !props.account.loggedIn && 
                                <>
                                    <Nav.Link as={Link} to={'/'} style={{marginRight: '15px'}}>O aplikaci</Nav.Link>
                                    <Nav.Link as={Link} to={'/login'} style={{marginRight: '15px', textDecoration: 'underline'}}>Přihlásit</Nav.Link>
                                </>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </React.Fragment>
    )
}

export default connect(
    ({ account }) => ({ account }),
    { logout }
  )(CustomNavbar);